<template>
  <div>
    <AppLayoutHeader :hide-hamburger-button="true" />
    <div class="form-container px">
      <Stage/>
      <div class="px-3">
        <h1 class="text-heading --prm text-center mt-4">{{ $t('text.register.title') }}</h1>
        <p class="text-title --w-medium mt-6 --dark">{{ $t('text.register.fill') }}</p>

        <v-form ref="form" class="mt-10">
          <p class="text-title --w-regular --dark">{{ $t('registerPage.email') }}</p>
          <p class="text-title --w-medium --x-gray mt-2">{{ email }}</p>

          <div class="mt-8">
            <label for="password" class="text-title --w-regular">{{ $t('common.password') }}</label>
            <v-text-field
              id="password"
              class="mt-2"
              v-model="form.password"
              :rules="rules.password"
              :append-icon="form.showPassword ? 'mdi-eye' : 'mdi-eye-off'"
              :type="form.showPassword ? 'text' : 'password'"
              solo
              flat
              filled
              :hint="$t('text.register.passwordHint')"
              background-color="var(--color__medium-smoke)"
              @click:append="form.showPassword = !form.showPassword"></v-text-field>

            <label for="passwordConfirmation" class="text-title --w-regular">{{ $t('text.register.passwordCon') }}</label>
            <v-text-field
              id="passwordConfirmation"
              class="mt-2"
              v-model="conPassword" :rules="rules.passwordConfirm" required
              :append-icon="form.showPasswordCon ? 'mdi-eye' : 'mdi-eye-off'"
              :type="form.showPasswordCon ? 'text' : 'password'"
              solo
              flat
              filled
              :hint="$t('text.register.passwordHint')"
              background-color="var(--color__medium-smoke)"
              @click:append="form.showPasswordCon = !form.showPasswordCon"></v-text-field>
          </div>

          <p class="text-medium-szm --dark --w-bold mb-12">{{ $t('text.register.required') }}</p>

          <div v-if="!isCompany">
            <label for="lastName" class="text-title --w-medium --dark">
              {{ $t('common.fullName') }}
              <span class="bg-pink text-small px-3 py-1 --border-r-2 ml-1 --white">{{ $t('reservationAndUsageHistory.mandatory') }}</span>
            </label>
            <v-row class="mt-0">
              <v-col>
                <v-text-field
                  id="lastName"
                  v-model="form.surname"
                  :rules="[$rules.required]"
                  outlined
                  solo
                  flat
                  :label="$t('common.surname')"/>
              </v-col>
              <v-col>
                <v-text-field
                  v-model="form.name"
                  :rules="[$rules.required]"
                  outlined
                  solo
                  flat
                  :label="$t('reservationAndUsageHistory.name')"/>
              </v-col>
            </v-row>

            <label for="sei" class="text-title --w-medium --dark">
              {{ $t('common.nameKana') }}
              <span class="bg-pink text-small px-3 py-1 --border-r-2 ml-1 --white">
                {{ $t('reservationAndUsageHistory.mandatory') }}
              </span>
            </label>
            <v-row class="mt-0">
              <v-col>
                <v-text-field
                  id="sei"
                  v-model="form.sei"
                  :rules="[$rules.required, $rules.katakana]"
                  outlined
                  solo
                  flat
                  :label="$t('common.sei')"/>
              </v-col>
              <v-col>
                <v-text-field
                  v-model="form.mei"
                  :rules="[$rules.required, $rules.katakana]"
                  outlined
                  solo
                  flat
                  :label="$t('common.mei')"/>
              </v-col>
            </v-row>
            <div class="text-normal mt-n5 mb-8 --w-regular --dark">
              {{ $t('text.register.nameNotChange') }}
            </div>

            <span class="text-title --w-medium --dark">
              {{ $t('common.sex') }}
              <span class="bg-pink text-small px-3 py-1 --border-r-2 ml-1 --white">
                {{ $t('reservationAndUsageHistory.mandatory') }}
              </span>
            </span>
            <v-radio-group row v-model="form.gender">
              <v-radio value="Male">
                <template v-slot:label>
                  <span class="text-normal --w-medium --dark">
                    {{ $t('common.male') }}
                  </span>
                </template>
              </v-radio>
              <v-radio value="Female" class="ml-10">
                <template v-slot:label>
                  <span class="text-normal --w-medium --dark">
                    {{ $t('common.female') }}
                  </span>
                </template>
              </v-radio>
              <v-radio value="Other" class="ml-10">
                <template v-slot:label>
                  <span class="text-normal --w-medium --dark">
                    {{ $t('common.other') }}
                  </span>
                </template>
              </v-radio>
            </v-radio-group>

            <p class="text-title --w-medium --dark">
              {{ $t('common.dateOfBirth') }}
              <span class="bg-pink text-small px-3 py-1 --border-r-2 ml-1 --white">
                {{ $t('reservationAndUsageHistory.mandatory') }}
              </span>
            </p>
            <v-row class="mt-2">
              <v-col class="d-flex">
                <v-select
                  :items="ageYearList"
                  :rules="rules.isRequired"
                  v-model="form.year"
                  ref="year"
                  @change="$refs.month.validate(); $refs.day.validate()"
                  placeholder="YYYY"
                  outlined
                  solo
                  flat
                />
                <span class="text-medium --w-regular --dark mt-4 ml-1">
                  {{ $t('common.year') }}
                </span>
              </v-col>
              <v-col class="d-flex">
                <v-select
                  :items="monthList"
                  :rules="rules.isRequired"
                  v-model="form.month"
                  ref="month"
                  @change="$refs.year.validate(); $refs.day.validate()"
                  placeholder="MM"
                  outlined
                  solo
                  flat
                />
                <span class="text-medium --w-regular --dark mt-4 ml-1">
                  {{ $t('common.month') }}
                </span>
              </v-col>
              <v-col class="d-flex">
                <v-text-field
                  type="number"
                  class="no-input-arrow"
                  v-model="form.day"
                  :rules="rules.day"
                  ref="day"
                  @change="$refs.year.validate(); $refs.month.validate()"
                  placeholder="DD"
                  outlined
                  solo
                  flat
                />
                <span class="text-medium --w-regular --dark mt-4 ml-1">
                  {{ $t('common.day') }}
                </span>
              </v-col>
            </v-row>
            <p class="text-normal mt-n5 mb-8 --w-regular --dark">
              {{ $t('text.register.birthNotChange') }}
            </p>
          </div>
          <div v-else>
            <label for="corporationName" class="text-title mt-3 --w-medium --dark">
              {{ $t('common.corporationName') }}
              <span class="bg-pink text-small px-3 py-1 --border-r-2 ml-1 --white">
                {{ $t('reservationAndUsageHistory.mandatory') }}
              </span>
            </label>
            <p class="text-medium-szm --w-regular mt-3 --dark">
              {{ $t('text.register.corporationNameExample') }}
            </p>
            <v-text-field
              id="corporationName"
              v-model="form.corporationName"
              :rules="[$rules.required]"
              outlined
              solo
              flat
              class="mt-2 --border-r-4"/>

            <label for="corporateName" class="text-title mt-3 --w-medium --dark">
              {{ $t('common.corporateName') }}
              <span class="bg-pink text-small px-3 py-1 --border-r-2 ml-1 --white">
                {{ $t('reservationAndUsageHistory.mandatory') }}
              </span>
            </label>
            <p class="text-medium-szm --w-regular mt-3 --dark">{{ $t('text.register.corporateNameExample') }}</p>
            <v-text-field
              id="corporateName"
              v-model="form.corporateName"
              :rules="[$rules.required, $rules.katakana]"
              outlined
              solo
              flat
              class="mt-2 --border-r-4"/>

            <label for="corporateNameNo" class="text-title mt-3 --w-medium --dark">
              {{ $t('common.corporateNameNo') }}
              <span class="bg-pink text-small px-3 py-1 --border-r-2 ml-1 --white">
                {{ $t('reservationAndUsageHistory.mandatory') }}
              </span>
            </label>
            <p class="text-medium-szm --w-regular mt-3 --dark">
              {{ $t('text.register.corporateNameNoExample') }}
            </p>
            <v-text-field
              id="corporateNameNo"
              v-model="form.corporateNameNo"
              :rules="rules.number"
              outlined
              solo
              flat
              class="mt-2 --border-r-4"/>

            <label for="nameOfPersonInCharge" class="text-title mt-3 --w-medium --dark">
              {{ $t('common.nameOfPersonInCharge') }}
              <span class="bg-pink text-small px-3 py-1 --border-r-2 ml-1 --white">
                {{ $t('reservationAndUsageHistory.mandatory') }}
              </span>
            </label>
            <p class="text-medium-szm --w-regular mt-3 --dark">{{ $t('text.register.nameOfPersonInChargeExample') }}</p>
            <v-text-field
              id="nameOfPersonInCharge"
              v-model="form.nameOfPersonInCharge"
              :rules="[$rules.required]"
              outlined
              solo
              flat
              class="mt-2 --border-r-4"/>

            <label for="katakana" class="text-title mt-3 --w-medium --dark">
              {{ $t('common.katakana') }}
              <span class="bg-pink text-small px-3 py-1 --border-r-2 ml-1 --white">
                {{ $t('reservationAndUsageHistory.mandatory') }}
              </span>
            </label>
            <p class="text-medium-szm --w-regular mt-3 --dark">
              {{ $t('text.register.katakanaExample') }}
            </p>
            <v-text-field
              id="katakana"
              v-model="form.katakana"
              :rules="[$rules.required, $rules.katakana]"
              outlined
              solo
              flat
              class="mt-2 --border-r-4"/>

            <label for="department" class="text-title mt-3 --w-medium --dark">
              {{ $t('common.nameDepartmentOrPosition') }}
              <span class="bg-pink text-small px-3 py-1 --border-r-2 ml-1 --white">
                {{ $t('reservationAndUsageHistory.mandatory') }}
              </span>
            </label>
            <v-row>
              <v-col>
                <p class="text-medium-szm --w-regular mt-3 --dark">
                  {{ $t('text.register.departmentExample') }}
                </p>
                <v-text-field
                  id="department"
                  v-model="form.department"
                  :rules="[$rules.required]"
                  outlined
                  solo
                  flat
                  class="mt-2 --border-r-4"/>
              </v-col>
              <v-col>
                <p class="text-medium-szm --w-regular mt-3 --dark">
                  {{ $t('text.register.positionExample') }}
                </p>
                <v-text-field
                  v-model="form.position"
                  :rules="[$rules.required]"
                  outlined
                  solo
                  flat
                  class="mt-2 --border-r-4"/>
              </v-col>
            </v-row>
          </div>

          <div class="text-title --prm --w-bold">
            {{ isCompany ? this.$t('text.register.companyAddress') : this.$t('text.register.homeAddress') }}
          </div>

          <div class="mt-3">
            <label for="postalCode" class="text-title --w-medium --dark">
              {{ $t('common.postalCode') }}
              <span class="bg-pink text-small px-3 py-1 --border-r-2 ml-1 --white">
              {{ $t('reservationAndUsageHistory.mandatory') }}
            </span>
            </label>
            <p class="text-medium-szm --w-regular my-3 --dark">{{ $t('text.register.postalCodeExample') }}</p>
            <v-row>
              <v-col cols="5">
                <PostalCodeInput
                  id="postalCode"
                  v-model="form.postalCode"
                  outlined
                />
              </v-col>
              <v-col>
                <v-btn
                  elevation="0"
                  class="text-medium bg-white"
                  x-large
                  @click="searchPostalCode()"
                  :loading="resolvingPostalCode"
                >
                  {{ $t('text.register.addressSearch') }}
                </v-btn>
              </v-col>
            </v-row>
          </div>

          <div class="mt-3">
            <label for="province" class="text-title --w-medium --dark">
              {{ $t('common.province') }}
              <span class="bg-pink text-small px-3 py-1 --border-r-2 ml-1 --white">{{ $t('reservationAndUsageHistory.mandatory') }}</span>
            </label>
            <p class="text-medium-szm --w-regular mt-3 --dark">{{ $t('text.register.provinceExample') }}</p>
            <prefecture-select
              id="province"
              v-model="form.province"
              :rules="[$rules.required]"
              class="mt-2"
            />
          </div>

          <div>
            <label for="district" class="text-title mt-0 --w-medium --dark">
              {{ $t('common.district') }}
              <span class="bg-pink text-small px-3 py-1 --border-r-2 ml-1 --white">
                {{ $t('reservationAndUsageHistory.mandatory') }}
              </span>
            </label>
            <p class="text-medium-szm --w-regular mt-3 --dark">{{ $t('text.register.districtExample') }}</p>
            <v-text-field
              id="district"
              v-model="form.district"
              :rules="[$rules.required]"
              outlined
              solo
              flat
              class="mt-2"/>
          </div>

          <div>
            <label for="street" class="text-title mt-0 --w-medium --dark">
              {{ $t('common.street') }}
              <span class="bg-pink text-small px-3 py-1 --border-r-2 ml-1 --white">{{ $t('reservationAndUsageHistory.mandatory') }}</span>
            </label>
            <p class="text-medium-szm --w-regular mt-3 --dark">{{ $t('text.register.streetExample') }}</p>
            <v-text-field
              id="street"
              v-model="form.street"
              :rules="[$rules.required]"
              outlined
              solo
              flat
              class="mt-2"
            />
          </div>

          <div>
            <label for="buildingName" class="text-title mt-0 --w-medium --dark">
              {{ $t('common.buildingName') }}
            </label>
            <p class="text-medium-szm --w-regular mt-3 --dark">{{ $t('text.register.buildingNameExample') }}</p>
            <v-text-field
              id="buildingName"
              v-model="form.buildingName"
              outlined
              solo
              flat
              class="mt-2"/>
          </div>

          <div class="text-title --prm --w-bold">
            {{ isCompany ? this.$t('text.register.companyContact') : this.$t('text.register.homeContact') }}
            <span class="text-medium-szm --w-regular --dark ml-2">*{{ $t('text.register.optionalFill') }}</span>
          </div>

          <div class="mt-4">
            <label for="phoneNumber" class="text-title mt-5 --w-medium --dark">
              {{ $t('common.phoneNumber') }}
            </label>
            <p class="text-medium-szm --w-regular mt-1 --dark">{{ $t('text.register.phoneNumberExample') }}</p>
            <v-text-field
              id="phoneNumber"
              v-model="form.phoneNumber"
              ref="phoneNumber"
              :rules="[
                v => rules.phoneNumber(v, form.cellNumber)
              ]"
              @change="$refs.cellNumber.validate()"
              outlined
              solo
              flat
              class="mt-2"/>
          </div>

          <div>
            <label for="cellNumber" class="text-title --w-medium --dark">{{ $t('common.cellPhoneNumber') }}</label>
            <p class="text-medium-szm --w-regular mt-1 --dark">{{ $t('text.register.cellPhoneNumberExample') }}</p>
            <v-text-field
              id="cellNumber"
              v-model="form.cellNumber"
              ref="cellNumber"
              :rules="[
                v => rules.phoneNumber(v, form.phoneNumber)
              ]"
              @change="$refs.phoneNumber.validate()"
              outlined
              solo
              flat
              class="mt-2"
            />
          </div>

          <div>
            <label for="membershipNumber" class="text-title mt-5 --w-medium --dark">{{ $t('contractDetail.membershipNumber') }}</label>
            <v-text-field
              id="membershipNumber"
              v-model="form.membershipNumber"
              outlined
              solo
              flat
              class="mt-2"
            />
          </div>

          <p class="text-medium-szm --w-regular --dark">{{ $t('text.register.descPrevent') }}</p>

          <v-btn
            elevation="0"
            class="btn-icon shadow-white my-5 text-heading"
            @click="commit()"
            block
            rounded
            color="btn-grad--orange"
            x-large
          >
            <span class="text-center w-100">{{ $t('buttons.next') }}</span>
            <v-icon color="var(--color__white)">mdi-chevron-right-circle-outline</v-icon>
          </v-btn>
        </v-form>
      </div>
    </div>
  </div>
</template>

<script>
import Stage from '@/components/StageRegister'
import {
  checkPassword,
  checkPostalCode,
  checkPhoneNumber,
  checkNumber
} from '@/utils/validate'
import AppLayoutHeader from '@/layout/AppLayout/components/AppLayoutHeader'
import {
  resolvePostalCode
} from '@/api/postal-code'
import { getMonthList, getYearListOfAge, isValidDate } from '@/utils/get-date'
import { sessionStorageMixin } from '@/mixins/session-storage.mixin'
import commonConstants from '@/views/Register/common.constants'
import PostalCodeInput from '@/components/Inputs/PostalCodeInput'
import PrefectureSelect from '../../../components/Inputs/PrefectureSelect.vue'

export default {
  components: {
    AppLayoutHeader,
    Stage,
    PostalCodeInput,
    PrefectureSelect
  },
  mixins: [sessionStorageMixin],
  data () {
    const defaultForm = {
      showPassword: false,
      showPasswordCon: false,
      password: '',
      surname: '',
      name: '',
      sei: '',
      mei: '',
      gender: null,
      year: '',
      month: '',
      day: '',
      postalCode: '',
      province: '',
      district: '',
      street: '',
      buildingName: '',
      phoneNumber: '',
      cellNumber: '',
      membershipNumber: '',
      // Company
      corporationName: '',
      corporateName: '',
      corporateNameNo: '',
      nameOfPersonInCharge: '',
      katakana: '',
      department: '',
      position: ''
    }
    const dataFromStorage = this.getFromStorage(commonConstants.storageKey)
    const validateTelNumber = (telNumber) => {
      if (!!telNumber && checkPhoneNumber(telNumber)) return true
      else if (!telNumber) return this.$t('rules.isRequired')
      else return this.$t('rules.invalid')
    }

    return {
      conPassword: '',
      email: dataFromStorage ? dataFromStorage.email : '',
      isCompany: dataFromStorage ? dataFromStorage.isCompany : '',
      token: null,
      promoCodeFromUrl: null,
      form: dataFromStorage ? dataFromStorage.memberInfo : defaultForm,
      rules: {
        password: [
          v => !!v || this.$t('rules.passwordIsRequired'),
          v => checkPassword(v) || this.$t('rules.passwordRules')
        ],
        passwordConfirm: [
          v => v === this.form.password || this.$t('rules.passwordNotMatch')
        ],
        postalCode: [
          v => !!v || this.$t('rules.isRequired'),
          v => checkPostalCode(v) || this.$t('rules.invalid')
        ],
        phoneNumber: (firstTelNumber, secondTelNumber) => {
          const validation = validateTelNumber(firstTelNumber)
          switch (validation) {
            case true:
              return true
            case this.$t('rules.isRequired'):
              return validateTelNumber(secondTelNumber) === true ? true : this.$t('rules.isRequired')
          }
          return validation
        },
        number: [
          v => !!v || this.$t('rules.isRequired'),
          v => checkNumber(v) || this.$t('rules.invalid')
        ],
        day: [
          v => !!v || this.$t('rules.isRequired'),
          v => (checkNumber(v) && isValidDate(parseInt(v), this.form.month, this.form.year)) || this.$t('rules.invalid')
        ]
      },
      text: {
        yourAddress: '',
        contactAddress: ''
      },
      resolvingPostalCode: false,
      ageYearList: getYearListOfAge(18),
      monthList: getMonthList()
    }
  },
  methods: {
    async commit () {
      if (this.$refs.form.validate()) {
        const data = {
          token: this.token,
          email: this.email,
          isCompany: this.isCompany,
          memberInfo: this.form,
          promoCodeFromUrl: this.promoCodeFromUrl
        }
        this.saveToStorage(commonConstants.storageKey, data)
        await this.$router.push({ name: 'register-payment-method' })
      }
    },
    async searchPostalCode () {
      if (this.form.postalCode) {
        try {
          await this.$doLoading(async () => {
            const resolved = await resolvePostalCode(this.form.postalCode)
            if (resolved) {
              this.form.province = resolved.address1
              this.form.district = resolved.address2
              this.form.street = resolved.address3
            }
          })
        } catch (e) {
          this.$toast.error('郵便番号検索に失敗しました。お手数をおかけしますが、各項目を手動でご入力下さい。')
        }
      }
    }
  },
  async mounted () {
    this.token = this.$route.fullPath.split('?')[1]

    await this.$doLoading(async () => {
      await this.$showGqlError(async () => {
        const data = await this.$store.dispatch('signupTokenInfo', {
          apollo: this.$apollo,
          token: this.token
        })

        this.email = data.data.vsSignupTokenInfo.email
        this.isCompany = data.data.vsSignupTokenInfo.isCompany
        this.promoCodeFromUrl = data.data.vsSignupTokenInfo.promoCode
      })
    })
  }
}
</script>
