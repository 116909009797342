import axios from 'axios'

const endpoint = process.env.VUE_APP_POSTALCODE_API || 'https://zipcloud.ibsnet.co.jp/api/search'

export async function resolvePostalCode (postalCode) {
  const res = await axios.get(endpoint, {
    params: {
      zipcode: postalCode
    }
  })
  return res.data?.results[0]
}
