<template>
  <v-select
    :items="selectItems"
    :value="value"
    solo
    flat
    :rules="rules"
    v-bind="$attrs"
    v-on="$listeners"
  />
</template>

<script>
import gql from 'graphql-tag'

export default {
  props: {
    value: String,
    rules: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return { items: [] }
  },
  computed: {
    selectItems () {
      return this.items.map(p => p.name)
    }
  },
  apollo: {
    items: {
      query: gql`query prefectureList { enumPrefectureList{ name } }`,
      update: data => data.enumPrefectureList
    }
  }
}
</script>
